.e-grid .e-headercell {
  height: 36px;
  letter-spacing: 0.05em;
  padding: 0px 0px 0px 0px;
  background: #f4f2ff;
  font-family: sans-serif;
}
.e-grid .e-headercelldiv {
  font-size: 13px;
  color: #8a92a6;
  font-weight: 700;
}
.e-grid .e-rowcell {
  height: 40px;
  font-size: 13px;
  color: #25213b;
  margin-right: 0px;
  margin-left: 0px;
  line-height: 26px;
  padding: 0px;
}
