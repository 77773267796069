.MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall
  .MuiAutocomplete-input {
  padding: 0px 4px !important;
}
.MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 14px !important;
}

.MuiPaper-root-MuiDialog-paper {
  border-radius: 21px !important;
}

.MuiInputBase-input {
  padding: 9px !important;
}
