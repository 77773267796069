@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.effect:hover {
  background-color: #fff;
}

.load-row {
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: flex;
  gap: 4px;
}

.load-row span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #f76002;
  border-radius: 50px;
  animation: up-down6 0.4s ease-in infinite alternate;
}

.load-row span:nth-child(2) {
  background: #e85b04c4;
  animation-delay: 0.16s;
}

.load-row span:nth-child(3) {
  background: #e85b0491;
  animation-delay: 0.32s;
}

.load-row span:nth-child(4) {
  background: #e85b0456;
  animation-delay: 0.48s;
}

@keyframes up-down6 {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(10px);
  }
}

.form {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.heading {
  text-align: center;
  color: black;
  font-weight: bold;
}

.check {
  align-self: center;
}

.btn2 {
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 5rem;
  border-radius: 1.5rem;
  background: #d6e9ec;
  background-size: 400%;
  border: none;
  font-size: 16px;
}

.btn2:hover {
  background-color: #1d1b25;
  transition: all 0.475s;
}

.btn2:hover::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
}

.btn2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  transition: all 0.475s;
}

#img-preview {
  display: none;
  width: 155px;
  height: fit-content;
  border: 2px dashed #333;
  margin-bottom: 20px;
}

#img-preview img {
  width: 100%;
  height: 100%;
  /* display: block; */
}

.rs-table-body-wheel-area {
  transition-duration: 0ms !important;
  transition-timing-function: ease in !important;
}

@import url(https://cdn.syncfusion.com/ej2/23.1.43/tailwind.css);
